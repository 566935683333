.form-div label{
  display:flex;
  flex-direction: column;
  color: #77828d;
  font-size: 0.8rem;
  width:full;
}

.form-div  label  input{
  border: 1px  #E4E6EA solid;
  border-radius: 6px;
  color: #77828d;
  margin: 4px 0 0 0;
  font-size: 1rem;
  padding: 0 0 0 12px;
  height:2.6em;
  outline: none;
  
}

.form-div label textarea {
  border: 1px  #E4E6EA solid;
  border-radius: 6px;
  color: #77828d;
  margin: 4px 0 0 0;
  font-size: 1rem;
  padding: 10px 0 0 12px;
  min-height:5em;
  outline: none;
}

.project-div label {
  border: 1px  #E4E6EA solid;
  border-radius: 6px;
  color: #77828d;
  margin: 4px 0 0 0;
  font-size: 1rem;
  padding: 0 16px;
  height:72px;
  outline: none;
  width:63%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}