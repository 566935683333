.gradient-div { 
  background-image: url("../../../src/assets/images/Rectangle\ 34624226.png"); 
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height:707px;
  position: absolute;  
  width: 100%;
  top: -10;
}  

